// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  position: relative;
}
.container .obsTitle {
  padding-bottom: 4.571rem;
}
@media screen and (max-width: 767px) {
  .container .obsTitle {
    padding-bottom: 0px;
  }
}
.container .bulletList {
  margin: 7rem auto auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 5.714rem;
  align-items: center;
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .container .bulletList {
    width: 100%;
    margin-top: 4rem;
  }
}

.fontText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.429rem;
  font-family: "Open Sans";
  font-weight: 300;
}

.textColor {
  color: #34322d;
  font-family: "Open Sans";
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/views/observatoire/style.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;AAEI;EACI,wBAAA;AAAR;AACQ;EAFJ;IAGQ,mBAAA;EAEV;AACF;AACI;EACI,sBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACR;AAAQ;EARJ;IASQ,WAAA;IACA,gBAAA;EAGV;AACF;;AACA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,wBAAA;EACA,gBAAA;AAEJ;;AACA;EACI,cC5Be;ED6Bf,wBAAA;EACA,gBAAA;AAEJ","sourcesContent":["@import 'assets/styles/colors.scss';\n\n.container{\n    position: relative;\n    .obsTitle{\n        padding-bottom: 4.571rem;\n        @media screen and (max-width: 767px) {\n            padding-bottom: 0px;\n        }\n    }\n\n    .bulletList{\n        margin: 7rem auto auto;\n        width: 70%;\n        display: flex;\n        flex-direction: column;\n        gap: 5.714rem;\n        align-items: center;\n        margin-bottom: 5rem;\n        @media screen and (max-width: 767px) {\n            width: 100%;\n            margin-top: 4rem;\n        }\n    }\n}\n\n.fontText{\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    font-size: 1.429rem;\n    font-family: \"Open Sans\";\n    font-weight: 300;\n}\n\n.textColor{\n    color: $color_anthracite;\n    font-family: \"Open Sans\";\n    font-weight: 600;\n}","$color_white: #ffffff;\n$color_observatoire-night: #151520;\n$color_observatoire-navy: #31314b;\n$color_observatoire-navy2: #4100FB;\n$color_observatoire-orange: #ff881a;\n$color_dusty-orange: #ebc8a7;\n$color_dusty-pink: #eaded9;\n$color_dark-grey: #6f6d69;\n$color_anthracite: #34322d;\n$color_light-grey: #e1e1e1;\n$color_light-grey2:#CCC9D4;\n$color_havanna: #f4ece4;\n$color_sweet-cream: #F0E9E6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
