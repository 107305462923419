import { ExpertiseLink } from "components/expertiseLink";
import { IObservation } from "models/IObservation";
import React, { FunctionComponent } from "react";
import "./expertise.scss";

interface ExpertiseProps {
    expertise: IObservation
}

export const Expertise: FunctionComponent<ExpertiseProps> = ({ expertise }) => {


    return (
        <div className="expertise">
            <div className="expertise__content">
                <p className="expertise__content__name open-sans-700">{expertise.name}</p>
                <p className="expertise__content__job open-sans-300">{expertise.job}</p>
                <p className="expertise__content__observation">{expertise.observation}</p>
                {expertise.bfmLink && <ExpertiseLink link={expertise.link} type={"arrow"} text={"Regarder l’interview sur BFM Business"} />}
                <ExpertiseLink link={expertise.etude} type={"download"} text={"Télécharger l’étude complète"} />
            </div>
            <div className="expertise__image" style={{backgroundImage:`url('${expertise.image}')`}}/>
        </div>
    );
};
