import React, { FunctionComponent, useEffect, useState } from "react";
import "./planetOrbit.scss";
import { Planet } from "assets/icons/planet";
import { lastEtude } from "views/etude/service";
import { IEtude } from "models/IEtudes";

export const PlanetOrbit: FunctionComponent = () => {
  let [isHovered, setHover] = useState(false);
  const [etude, setEtude] = useState<IEtude>();

  useEffect(() => {
    async function anyNameFunction() {
      const etude = await lastEtude();
      setEtude(etude);
    }
    anyNameFunction();
  }, []);

  return (
    <div
      className="planet"
      onClick={(e) => {
        e.preventDefault();
        window.open(etude?.etude, "_blank");
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Planet
        colorArrow={isHovered ? "#31314B" : "white"}
        colorRing={isHovered ? "#4100FB" : "url(#paint6_linear_1312_255)"}
      />
      <p
        className={
          "gilroy-700 planet-text " +
          (isHovered ? "planet-text-secondary" : "planet-text-primary")
        }
      >
        Accéder à la dernière étude
      </p>
    </div>
  );
};
