import { IBanner } from "models/IBanner";
import { FunctionComponent } from "react";
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import './banner.scss';

interface BannerProps {
    banner: IBanner[]
}

export const Banner: FunctionComponent<BannerProps> = ({ banner }) => {

    const collection = (Number(window.location.search.split('=')[1]));

    const navigate = useNavigate();
    const changeEtude = (collection: number) => {
        navigate(`?edition=${collection}`)
    }

    return (
        <div className="banner">
            {banner?.length !== 0 && banner.map((item, key) => {
                return (<div className={(item.titles.find(element => element.collection === collection) ? "banner__selected" : "")} key={key}>
                    <p className="banner__year open-sans-700">{item.year}</p>
                    <div key={key} className="banner__titles">
                        {item?.titles?.length !== 0 && item.titles.map((elmt, key) => {
                            return (
                                <div key={key} className="banner__title">
                                    <p onClick={() => changeEtude(elmt.collection)} title={elmt.title} className={"banner__titles__value " + (elmt.collection === collection ? "banner__titles__value__selected" : "")}>
                                        {elmt.title}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                )
            })}
        </div>
    );
};
