// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulletBloc {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
@media screen and (max-width: 767px) {
  .bulletBloc {
    gap: 0.857rem;
  }
}
.bulletBloc-image {
  width: 21px;
  height: 21px;
}
.bulletBloc-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media screen and (max-width: 767px) {
  .bulletBloc-content {
    gap: 1.143rem;
  }
}
.bulletBloc-content-title {
  margin: 0;
  font-size: 2rem;
  line-height: 1.8rem;
  letter-spacing: 0em;
  text-align: left;
  color: #31314b;
}
@media screen and (max-width: 767px) {
  .bulletBloc-content-title {
    font-size: 2rem;
    width: 80%;
  }
}
.bulletBloc-content-text {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/bullet/bullet.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AADJ;AAEI;EAJJ;IAKQ,aAAA;EACN;AACF;AAAI;EACI,WAAA;EACA,YAAA;AAER;AACI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACR;AAAQ;EAJJ;IAKQ,aAAA;EAGV;AACF;AAFQ;EACI,SAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cCzBc;AD6B1B;AAHY;EAPJ;IAQQ,eAAA;IACA,UAAA;EAMd;AACF;AAJQ;EACI,SAAA;AAMZ","sourcesContent":["@import \"assets/styles/colors.scss\";\n\n.bulletBloc {\n    display: flex;\n    flex-direction: row;\n    gap: 25px;\n    @media screen and (max-width: 767px) {\n        gap: 0.857rem;\n    }\n    &-image {\n        width: 21px;\n        height: 21px;\n    }\n\n    &-content {\n        display: flex;\n        flex-direction: column;\n        gap: 8px;\n        @media screen and (max-width: 767px) {\n            gap: 1.143rem;\n        }\n        &-title {\n            margin: 0;\n            font-size: 2rem;\n            line-height: 1.8rem;\n            letter-spacing: 0em;\n            text-align: left;\n            color: $color_observatoire-navy;\n            @media screen and (max-width: 767px) {\n                font-size: 2rem;\n                width: 80%;\n            }\n        }\n        &-text {\n            margin: 0;\n        }\n    }\n}\n","$color_white: #ffffff;\n$color_observatoire-night: #151520;\n$color_observatoire-navy: #31314b;\n$color_observatoire-navy2: #4100FB;\n$color_observatoire-orange: #ff881a;\n$color_dusty-orange: #ebc8a7;\n$color_dusty-pink: #eaded9;\n$color_dark-grey: #6f6d69;\n$color_anthracite: #34322d;\n$color_light-grey: #e1e1e1;\n$color_light-grey2:#CCC9D4;\n$color_havanna: #f4ece4;\n$color_sweet-cream: #F0E9E6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
