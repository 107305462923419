// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expertiseLink {
  display: flex;
  flex-direction: row;
  padding: 25px 8px;
  justify-content: space-between;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.expertiseLink:nth-last-child(n+2):nth-child(2n) {
  border-bottom: 0px solid #ffffff;
}
.expertiseLink-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .expertiseLink-text {
    font-family: "Open Sans";
    font-weight: 400;
  }
}

.expertiseLink:hover .onHover {
  color: #ff881a;
}`, "",{"version":3,"sources":["webpack://./src/components/expertiseLink/expertiseLink.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,8BAAA;EACA,6BAAA;EACA,gCAAA;AADJ;AAEI;EACI,gCAAA;AAAR;AAEI;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,cCpBM;EDqBN,qBAAA;EACA,eAAA;AAAR;AACQ;EAXJ;IAYQ,wBAAA;IACA,gBAAA;EAEV;AACF;;AACA;EACI,cC1BwB;AD4B5B","sourcesContent":["@import 'assets/styles/colors.scss';\n\n.expertiseLink {\n    display: flex;\n    flex-direction: row;\n    padding: 25px 8px;\n    justify-content: space-between;\n    border-top: 1px solid $color_white;\n    border-bottom: 1px solid $color_white;\n    &:nth-last-child(n+2):nth-child(2n){\n        border-bottom: 0px solid $color_white;\n    }\n    &-text {\n        display: flex;\n        justify-content: space-between;\n        width: 100%;\n        font-size: 1rem;\n        line-height: 19px;\n        letter-spacing: 0.01em;\n        text-align: center;\n        color: $color_white;\n        text-decoration: none;\n        cursor: pointer;\n        @media screen and (max-width: 767px) {\n            font-family: \"Open Sans\";\n            font-weight: 400;\n        }\n    }\n}\n.expertiseLink:hover .onHover{\n    color: $color_observatoire-orange;\n}\n","$color_white: #ffffff;\n$color_observatoire-night: #151520;\n$color_observatoire-navy: #31314b;\n$color_observatoire-navy2: #4100FB;\n$color_observatoire-orange: #ff881a;\n$color_dusty-orange: #ebc8a7;\n$color_dusty-pink: #eaded9;\n$color_dark-grey: #6f6d69;\n$color_anthracite: #34322d;\n$color_light-grey: #e1e1e1;\n$color_light-grey2:#CCC9D4;\n$color_havanna: #f4ece4;\n$color_sweet-cream: #F0E9E6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
