// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrowAnimate {
  position: absolute;
  top: 1%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  animation: mover 0.5s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/arrowAnimate/arrow.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,OAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;EAEA,wCAAA;AACJ;;AAEA;EACI;IAAK,wBAAA;EAEP;EADE;IAAO,4BAAA;EAIT;AACF","sourcesContent":[".arrowAnimate{\n    position: absolute;\n    top: 1%;  \n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 1rem;\n    -webkit-animation: mover 0.5s infinite  alternate;\n    animation: mover 0.5s infinite  alternate;\n}\n\n@keyframes mover {\n    0% { transform: translateY(0); }\n    100% { transform: translateY(-10px); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
