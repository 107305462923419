import React, { FunctionComponent } from "react";
import "./presse.scss";
import { Button } from "components/button";
import { IPresse } from "models/IPresse";

interface PresseProps {
    presse: IPresse
}

export const PresseItem: FunctionComponent<PresseProps> = ({ presse }) => {

    return (
        <div className="presseElmt">
            <img className="presseElmt-image" src={presse.image} alt={`presse ${presse.id}`} />
            <div className="presseElmt-content">
                <p className="presseElmt-content-date open-sans-700">{presse.date}</p>
                <p className="presseElmt-content-title gilroy-700">{presse.title}</p>
                <p className="presseElmt-content-text">{presse.description}</p>
                <div className="presseElmt-content-button">
                    <Button button={{ text: presse.buttonText, color: "primary", type: "type2", link: presse.buttonLink }} />
                </div>
            </div>
        </div>
    );
};
