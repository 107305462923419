import React, { FunctionComponent } from "react";

interface Props {
    color?: string;
}

export const Arrow1: FunctionComponent<Props> = ({
    color,
}) => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1133 1.26269C14.0039 1.16243 13.8763 1.08496 13.7305 1.03027C13.5938 0.96647 13.4479 0.934569 13.293 0.934569C13.2292 0.934569 13.1654 0.939127 13.1016 0.948241C13.0469 0.957356 12.9876 0.971028 12.9238 0.989257H12.9375L1.24805 4.88574C1.01107 4.95866 0.815104 5.09538 0.660156 5.2959C0.514322 5.49642 0.441406 5.72428 0.441406 5.97949C0.441406 6.19824 0.496093 6.39876 0.605468 6.58105C0.723958 6.76335 0.874349 6.90462 1.05664 7.00488H1.07031L5.91016 9.39746L8.31641 14.2646C8.40755 14.4561 8.54427 14.6064 8.72656 14.7158C8.90885 14.8343 9.11393 14.8936 9.3418 14.8936H9.42383C9.66081 14.8753 9.87044 14.7933 10.0527 14.6475C10.235 14.5016 10.3581 14.3193 10.4219 14.1006L14.3867 2.43848C14.4049 2.38379 14.4186 2.32454 14.4277 2.26074C14.4368 2.19694 14.4414 2.13314 14.4414 2.06934C14.4414 1.91439 14.4095 1.76855 14.3457 1.63184C14.291 1.486 14.2135 1.36295 14.1133 1.26269ZM1.74023 5.99316L11.9395 2.58887L6.2793 8.24902L1.74023 5.99316ZM9.39648 13.6357L7.12695 9.09668L12.7871 3.43652L9.39648 13.6357Z" fill={color} />
        </svg>
    );
};
