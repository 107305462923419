// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.planet {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  position: sticky;
  z-index: 1;
  top: 5px;
  cursor: pointer;
  margin-right: -9.286rem;
}
@media screen and (max-width: 767px) {
  .planet {
    margin-right: -0.286rem;
  }
}
.planet-text {
  font-size: 0.643rem;
  line-height: 0.786rem;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  width: 45%;
}
.planet-text-primary {
  color: #fb7c37;
  transform: scale(1);
}
.planet-text-secondary {
  color: #4100FB;
  transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/components/planetOrbit/planetOrbit.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,UAAA;EACA,QAAA;EACA,eAAA;EACA,uBAAA;AADJ;AAEI;EAXJ;IAYQ,uBAAA;EACN;AACF;AAAI;EACI,mBAAA;EACA,qBAAA;EACA,qBAAA;EACA,kBAAA;EACA,yBAAA;EACA,UAAA;AAER;AADQ;EACI,cAAA;EACA,mBAAA;AAGZ;AADQ;EACI,cCzBe;ED0Bf,qBAAA;AAGZ","sourcesContent":["@import \"assets/styles/colors.scss\";\n\n.planet {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    width: fit-content;\n    margin: auto;\n    position: sticky;\n    z-index: 1;\n    top: 5px;\n    cursor: pointer;\n    margin-right: -9.286rem;\n    @media screen and (max-width: 767px) {\n        margin-right: -0.286rem;\n    }\n    &-text {\n        font-size: 0.643rem;\n        line-height: 0.786rem;\n        letter-spacing: 0.2em;\n        text-align: center;\n        text-transform: uppercase;\n        width: 45%;\n        &-primary {\n            color: #fb7c37;\n            transform: scale(1);\n        }\n        &-secondary {\n            color: $color_observatoire-navy2;\n            transform: scale(1.1);\n        }\n    }\n}\n","$color_white: #ffffff;\n$color_observatoire-night: #151520;\n$color_observatoire-navy: #31314b;\n$color_observatoire-navy2: #4100FB;\n$color_observatoire-orange: #ff881a;\n$color_dusty-orange: #ebc8a7;\n$color_dusty-pink: #eaded9;\n$color_dark-grey: #6f6d69;\n$color_anthracite: #34322d;\n$color_light-grey: #e1e1e1;\n$color_light-grey2:#CCC9D4;\n$color_havanna: #f4ece4;\n$color_sweet-cream: #F0E9E6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
