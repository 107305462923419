import React, { FunctionComponent } from "react";
import "./style.scss";

interface PageHeaderProps {
  text: string;
  points: boolean;
}

export const PageHeader: FunctionComponent<PageHeaderProps> = ({ text, points }) => {

  return (
    <div className="container__pageHeader">
      <div className="container__pageHeader__content">
        <h1 className="text gilroy-600">{text}</h1>
        {points &&
          <div className="bloc__points">
            <div className="point"></div>
            <div className="point"></div>
            <div className="point"></div>
          </div>
        }
      </div>
    </div>
  );
};
