import { Line } from "assets/icons/line";
import { Link } from "react-router-dom";
import "./miniNavbar.scss";

interface MiniNavbarProps {
  left: boolean;
  text1: string;
  link1: string;
  text2: string;
  link2: string;
  right: boolean;
}

export const MiniNavbar = ({
  left,
  right,
  text1,
  text2,
  link1,
  link2,
}: MiniNavbarProps) => {
  return (
    <div className="miniNavbar">
      <div className="miniNavbar-left">
        {left && (
          <div className="leftLine">
            <Line color={"#31314B"} />
          </div>
        )}
        <Link className="miniNavbar-link open-sans-700 onHover" to={link1}>
          {text1}
        </Link>
      </div>
      <div className="miniNavbar-right">
        <Link className="miniNavbar-link open-sans-700 onHover" to={link2}>
          {text2}
        </Link>
        {right && (
          <div>
            <Line color={"#31314B"} />
          </div>
        )}
      </div>
    </div>
  );
};
