import React, { FunctionComponent } from "react";
import logo1 from "assets/images/bullet.png"
import "./bullet.scss";

interface BulletProps {
    title: string;
    children: React.ReactNode
}

export const Bullet: FunctionComponent<BulletProps> = ( { title , children }) => {

    return (
        <div className="bulletBloc">
            <img className="bulletBloc-image" src={logo1} alt="bullet"/>
            <div className="bulletBloc-content">
                <p className="bulletBloc-content-title gilroy-700">{title}</p>
                {children}
            </div>
        </div>
    );
};
