import { IEtude, IEtudeData, IEtudeDataRaw, IEtudeRaw } from "models/IEtudes";
import env from "react-dotenv";
import etudeService from "services/etudeService";


export const allEtudes = (lang: string = "fr"): Promise<IEtude[]> => etudeService.getAllEtude(lang)
    .then((etudes) => {
        return handleEtudeData(etudes);
    })

const handleEtudeData = (etudes: IEtudeRaw): IEtude[] => {
    return etudes?.data?.map((etude: IEtudeDataRaw) => ({
        id: etude.id,
        year: etude.attributes?.Year,
        collection: etude.attributes?.Number,
        month: etude.attributes?.Month,
        title1: etude.attributes?.Title1,
        subtitle1: etude.attributes?.Subtitle1,
        kpi1Title: etude.attributes?.Kpi1Title,
        kpi1Text: etude.attributes?.Kpi1Text,
        kpi2Title: etude.attributes?.Kpi2Title,
        kpi2Text: etude.attributes?.Kpi2Text,
        title2: etude.attributes?.Title2,
        subtitle2: etude.attributes?.Subtitle2,
        name: etude.attributes?.Name,
        job: etude.attributes?.Job,
        observation: etude.attributes?.Observation,
        bfmLink: etude.attributes?.BFMLink,
        link: etude.attributes?.Link,
        etude: etude.attributes?.Etude,
        image: env.STRAPI_BASE_URL + etude.attributes.Image.data?.attributes?.url,
    }))
};

export const lastEtude = (): Promise<IEtude> => etudeService.getLastEtude()
    .then((etude) => {
        return handleOneEtude(etude);
    })

const handleOneEtude = (etude: IEtudeData): IEtude => {
    let etud: IEtude = {
        id: etude?.data.id,
        year: etude?.data.attributes.Year,
        collection: etude?.data.attributes.Number,
        month: etude?.data.attributes.Month,
        title1: etude?.data.attributes.Title1,
        subtitle1: etude?.data.attributes.Subtitle1,
        kpi1Title: etude?.data.attributes.Kpi1Title,
        kpi1Text: etude?.data.attributes.Kpi1Text,
        kpi2Title: etude?.data.attributes.Kpi2Title,
        kpi2Text: etude?.data.attributes.Kpi2Text,
        title2: etude?.data.attributes.Title2,
        subtitle2: etude?.data.attributes.Subtitle2,
        name: etude?.data.attributes.Name,
        job: etude?.data.attributes.Job,
        observation: etude?.data.attributes.Observation,
        bfmLink: etude?.data.attributes.BFMLink,
        link: etude?.data.attributes.Link,
        etude: etude?.data.attributes.Etude,
        image: env.STRAPI_BASE_URL + etude?.data.attributes.Image.data?.attributes?.url,
    }
    return etud;
};

export const findOneEtude = (collection: number): Promise<IEtude> => etudeService.getOneEtude(collection)
    .then((etude) => {
        return handleOneEtude(etude);
    })