import { Arrow3 } from "assets/icons/arrow3";
import { Download } from "assets/icons/download";
import React, { FunctionComponent, useState } from "react";
import "./expertiseLink.scss";

interface ExpertiseLinkProps {
    text: string;
    link?: string;
    type: string;
}

export const ExpertiseLink: FunctionComponent<ExpertiseLinkProps> = ({ link, type, text }) => {

    let [isHovered, setHover] = useState(false);

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className="expertiseLink">
            <a href={link} className="expertiseLink-text open-sans-300 onHover" target="_blank" rel="noreferrer">
                {text}
                {type === "download" && <Download color={isHovered ? '#FF881A' : '#FFFFFF'} />}
                {type === "arrow" && <Arrow3 color={isHovered ? '#FF881A' : '#FFFFFF'} />}
            </a>
        </div>
    );
};
