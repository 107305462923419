import { IPresse, IPresseDataRaw, IPresseRaw } from "models/IPresse";
import env from "react-dotenv";
import presseService from "services/presseService";


export const allPresse = (lang:string ="fr",page:number): Promise<IPresse[]> => presseService.getAllPresse(lang,page)
    .then((presses: IPresseRaw) => {
        return handlePresseData(presses);
    })


const handlePresseData = (presses: IPresseRaw): IPresse[] => {
    const data = presses?.data?.map((presse: IPresseDataRaw) => ({
        id: presse.id,
        title: presse.attributes?.Title,
        date: presse.attributes?.Date,
        description: presse.attributes?.Description,
        buttonLink: presse.attributes?.Link,
        buttonText: presse.attributes?.Button,
        image: env.STRAPI_BASE_URL + presse.attributes.Image.data?.attributes?.url,
        total: presses.meta.pagination.total
    }))

    return data;
};