import React, { FunctionComponent } from "react";

interface Props {
    background?: string;
}

export const Burger: FunctionComponent<Props> = ({
    background
}) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill={background} xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="15.25" stroke="#FF881A" strokeWidth="1.5" />
            <path d="M6.42773 10.4289C6.42773 10.1133 6.68357 9.85742 6.99916 9.85742H24.142C24.4576 9.85742 24.7134 10.1133 24.7134 10.4289C24.7134 10.7444 24.4576 11.0003 24.142 11.0003H6.99916C6.68357 11.0003 6.42773 10.7444 6.42773 10.4289ZM6.42773 16.1431C6.42773 15.8275 6.68357 15.5717 6.99916 15.5717H24.142C24.4576 15.5717 24.7134 15.8275 24.7134 16.1431C24.7134 16.4587 24.4576 16.7146 24.142 16.7146H6.99916C6.68357 16.7146 6.42773 16.4587 6.42773 16.1431ZM6.99916 21.286C6.68357 21.286 6.42773 21.5418 6.42773 21.8574C6.42773 22.173 6.68357 22.4289 6.99916 22.4289H24.142C24.4576 22.4289 24.7134 22.173 24.7134 21.8574C24.7134 21.5418 24.4576 21.286 24.142 21.286H6.99916Z" fill="#FF881A" />
        </svg>
    );
};
