import React, { FunctionComponent, useState } from "react";
import { Arrow1 } from "assets/icons/arrow1";
import { Arrow2 } from "assets/icons/arrow2";
import "./button.scss";
import { IButton } from "models/IButton";

interface ButtonProps {
    button: IButton;
}

export const Button: FunctionComponent<ButtonProps> = ({ button }) => {

    let [isHovered, setHover] = useState(false);

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {button.type === "type1"  && <a className={`color--${button.color} button`} href="mailto:contact@saegus.com">
                <p className="button-link onHover gilroy-700">{button.text}</p>
                <Arrow1 color={isHovered ? '#FF881A' : '#FFFFFF'} />
            </a>}
            {button.type === "type2" && <a className={`color--${button.color} button`} href={button.link} target="_blank" rel="noreferrer">
                <p className="button-link onHover gilroy-700">{button.text}</p>
                <Arrow2 color={isHovered ? '#FF881A' : '#FFFFFF'} />
            </a>}
        </div>
    );
};
