import React, { FunctionComponent } from "react";
import arrow from "../../assets/images/Arrow.png";

import './arrow.scss';

export const AnimateArrow: FunctionComponent = () => {

    return (
        <>
            <img className="arrowAnimate" src={arrow} alt="arrow down" />
        </>
    );
};
