import React, { FunctionComponent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./footer.scss";
import LogoObs from "../../assets/images/logo-obs.png";
import LogoSaegus from "../../assets/images/new-logo-saegus.png";
import LogoObsMobile from "../../assets/images/logo-obs-mobile.svg";
import LogoSaegusMobile from "../../assets/images/logo-saegus-mobile.svg";


export const Footer: FunctionComponent = () => {

    const windowSize = window.screen.width;

    const currentPage = useLocation().pathname;
    const navigate = useNavigate();

    const goToPage = (pageName: string) => {
        if (pageName === currentPage) window.scrollTo(0, 0)
        navigate(pageName);
    }

    return (
        <div className="footer">
            <div className="footer-top">
                <div className="footer-top-left">
                    <div className="footer-top-left-bloc">
                        <p className="footer-top-left-bloc-navigation open-sans-600">Navigation</p>
                        <p onClick={() => goToPage("/etude")} className={`footer-top-left-bloc-link${currentPage === "/etude" ? "--active" : ""}`}>Études</p>
                        <p onClick={() => goToPage("/observatoire")} className={`footer-top-left-bloc-link${currentPage === "/observatoire" ? "--active" : ""}`}>L’Observatoire</p>
                        <p onClick={() => goToPage("/presse")} className={`footer-top-left-bloc-link${currentPage === "/presse" ? "--active" : ""}`}>Presse</p>
                        <a href="https://saegus.com/" className="footer-top-left-bloc-link" target="_blank" rel="noreferrer">Saegus </a>
                    </div>
                </div>
                <div className="footer-top-right">
                    <div className="footer-top-right-bloc">
                        <p className="footer-top-right-bloc-title open-sans-600">contact presse</p>
                        <a href="mailto:presse@saegus.com" className="footer-top-right-bloc-mailto open-sans-300">
                            presse@saegus.com
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-bottom-copyright open-sans-300">Copyright © 2023. Saegus. All rights reserved.</div>
                <div className="footer-bottom-logo">
                    <img src={windowSize >= 767 ? LogoObs : LogoObsMobile} alt="Logo observatoire" />
                    <img src={windowSize >= 767 ? LogoSaegus : LogoSaegusMobile} alt="Logo saegus" />
                </div>
            </div>
        </div>
    );
};
