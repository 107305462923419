import http from "../http";

class EtudeService {

    getAllEtude(lang: string) {
        return http.get(`/api/etudes?locale=${lang}&populate=*`)
            .then(response => {
                const data = response.data;
                return data;
            }).catch(error => {
                console.log(error);
            });
    }

    getLastEtude(lang: string = "fr") {
        return http.get(`api/etudes/last?locale=${lang}`)
            .then(response => {
                const data = response.data;
                return data;
            }).catch(error => {
                console.log(error);
            });
    }

    getOneEtude(collection: number, lang: string = "fr") {
        return http.get(`api/etudes/${collection}?locale=${lang}`)
            .then(response => {
                const data = response.data;
                return data;
            }).catch(error => {
                console.log(error);
            });
    }


}



export default new EtudeService();