import React, { FunctionComponent } from "react";
import "./style.scss";

export const BlocObservatoire: FunctionComponent = () => {

    return (
        <div className="blocObservatoire">
            <div className="blocObservatoire__1">
                <p className="blocObservatoire__1__title gilroy-700">L’Observatoire</p>
                <p className="blocObservatoire__1__text blocObservatoire__1__text__1 gilroy-700">des nouveaux usages<span className="blocObservatoire__1__text__2"> ?</span></p>
            </div>
            <div className="blocObservatoire__2">
                <p className="blocObservatoire__2__text gilroy-300">
                Anciennement Observatoire de la Digitalisation, Saegus fait évoluer ce format pour s’intéresser plus largement aux nouveaux usages. Si la transition numérique se poursuit pour une majorité des entreprises, les changements sociaux, économiques, politiques et environnementaux imposent d’en étudier l’impact au-delà de la technologie, dans sa déclinaison au service de l’humain et donc de ses usages.
                </p>
            </div>
        </div>
    );
};