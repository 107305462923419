// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.etudeHeader__title {
  font-size: 5.429rem;
  line-height: 6.5rem;
  letter-spacing: -0.01em;
  text-align: left;
  color: #31314b;
}
@media screen and (max-width: 767px) {
  .etudeHeader__title {
    font-size: 3.3rem;
    line-height: 4.5rem;
  }
}
.etudeHeader__text {
  font-size: 1.714rem;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #34322d;
}
@media screen and (max-width: 767px) {
  .etudeHeader__text {
    font-size: 1.31rem;
    line-height: normal;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/etudeHeader/etudeHeader.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAGI;EACI,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,cCNkB;ADI1B;AAGQ;EANJ;IAOQ,iBAAA;IACA,mBAAA;EAAV;AACF;AAEI;EACI,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cCZW;ADYnB;AACQ;EAPJ;IAQQ,kBAAA;IACA,mBAAA;EAEV;AACF","sourcesContent":["@import 'assets/styles/colors.scss';\n\n.etudeHeader {\n    &__title {\n        font-size: 5.429rem;\n        line-height: 6.5rem;\n        letter-spacing: -0.01em;\n        text-align: left;\n        color: $color_observatoire-navy;\n        @media screen and (max-width: 767px) {\n            font-size: 3.3rem;\n            line-height: 4.5rem;\n        }\n    }\n    &__text {\n        font-size: 1.714rem;\n        font-weight: 400;\n        line-height: 33px;\n        letter-spacing: 0em;\n        text-align: left;\n        color: $color_anthracite;\n        @media screen and (max-width: 767px) {\n            font-size: 1.31rem;\n            line-height: normal;\n        }\n    }\n}\n","$color_white: #ffffff;\n$color_observatoire-night: #151520;\n$color_observatoire-navy: #31314b;\n$color_observatoire-navy2: #4100FB;\n$color_observatoire-orange: #ff881a;\n$color_dusty-orange: #ebc8a7;\n$color_dusty-pink: #eaded9;\n$color_dark-grey: #6f6d69;\n$color_anthracite: #34322d;\n$color_light-grey: #e1e1e1;\n$color_light-grey2:#CCC9D4;\n$color_havanna: #f4ece4;\n$color_sweet-cream: #F0E9E6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
