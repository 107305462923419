import React, { FunctionComponent } from "react";

interface Props {
    color?: string;
}

export const Arrow3: FunctionComponent<Props> = ({
    color,
}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.48822 1.90624C9.35287 1.77089 9.18853 1.70322 8.99518 1.70322C8.80827 1.69678 8.64715 1.76122 8.5118 1.89657C8.37646 2.03191 8.30879 2.19626 8.30879 2.38961C8.30879 2.58296 8.37646 2.74731 8.5118 2.88265L12.8525 7.22335L1.6479 7.21368C1.55767 7.21368 1.46421 7.22979 1.36754 7.26202C1.28376 7.29424 1.20642 7.3458 1.13552 7.4167C1.00018 7.55204 0.932503 7.71639 0.932503 7.90974C0.932503 8.10309 1.00018 8.26744 1.13552 8.40278C1.20642 8.47368 1.28376 8.52524 1.36754 8.55746C1.46421 8.58969 1.55767 8.6058 1.6479 8.6058L12.8525 8.59613L8.5118 12.9368C8.37646 13.0722 8.30879 13.2365 8.30879 13.4299C8.30879 13.6232 8.37646 13.7876 8.5118 13.9229C8.64715 14.0583 8.80827 14.1227 8.99518 14.1163C9.18853 14.1163 9.35287 14.0486 9.48822 13.9132L15.0083 8.39311C15.0406 8.36089 15.0696 8.32544 15.0954 8.28677C15.1211 8.2481 15.1437 8.20621 15.163 8.16109L15.1534 8.17076C15.2049 8.05475 15.2178 7.9323 15.192 7.8034C15.1791 7.6745 15.1276 7.55849 15.0374 7.45537L9.48822 1.90624Z" fill={color} />
        </svg>
    );
};
