import { ILink } from "models/ILink";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.scss";

export const Navbar: FunctionComponent = () => {

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname)

  const [links, setLinks] = useState<ILink[]>([
    {
      id: 1,
      title: "études",
      link: "/",
      active: currentPath.includes("etude") ? true : false
    },
    {
      id: 2,
      title: "L'observatoire",
      link: "/observatoire",
      active: currentPath.includes("observatoire") ? true : false
    },
    {
      id: 3,
      title: "presse",
      link: "/presse?page=1",
      active: currentPath.includes("presse") ? true : false
    }
  ]);

  useEffect(() => {
    setCurrentPath(location.pathname);
    const linkId = (links?.find(elem => elem.link.includes(location.pathname))?.id || 1) - 1 ;
    handleClick(linkId);
  }, [location])

  const handleClick = async (index: number) => {
    var tmpNav: ILink[] = [];
    for (var i: number = 0; i < links.length; i++) {
      if (i === index) {
        links[i].active = true;
        tmpNav.push(links[i]);
      } else {
        links[i].active = false;
        tmpNav.push(links[i]);
      }
    }
    setLinks(tmpNav);
  };

  return (
    <>
      {
        links?.length !== 0 &&
        <div className="navbar">
          {links.map((item, key) => {
            return (
              <Link key={key} onClick={() => handleClick(key)} className={"navbar-link " + (item.active ? "active" : "")} to={item.link}>{item.title}</Link>
            )
          })}
        </div>
      }
    </>
  );
};
