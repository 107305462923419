import { Navbar } from "components/navbar";
import React, { FunctionComponent } from "react";
import Logo1 from "../../assets/images/Observatoire.png";
import Logo2 from "../../assets/images/ObsxSae.png";
// import Logo2 from "../../assets/images/logo-obs.png";
// import Logo3 from "../../assets/images/new-logo-saegus.png";
import "./header.scss";

export const Header: FunctionComponent = () => {


    return (
        <div className="header">
            <div className="header-bloc">
                <img className="logo1" src={Logo1} alt="logos" />
                <p className="header-bloc-title gilroy-700">des nouveaux usages</p>
                <p className="header-bloc-subtitle open-sans-300">
                Si la transition numérique se poursuit pour une majorité des entreprises, les changements sociaux, économiques, politiques et environnementaux imposent d’en étudier l’impact au-delà de la technologie, dans sa déclinaison au service de l’humain et donc de ses usages. Tous les deux mois, nous interrogeons les Français et professionnels de la tech sur les thématiques qui façonnent leur quotidien.
                </p>
            </div>
             <img className="logo2" src={Logo2} alt="logo observatoire" />
            {/* <div className="header-images">
                <img className="logo2" src={Logo2} alt="logo observatoire" />
                <img className="logo2" src={Logo3} alt="logo saegus" />
                <img src={Logo2} alt="logo observatoire" />
                <img src={Logo3} alt="logo saegus" />
            </div> */}
            <Navbar />
        </div>
    );
};
