import React, { FunctionComponent } from "react";

interface Props {
    color?: string;
}

export const Download: FunctionComponent<Props> = ({
    color,
}) => {
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 12.5H10.5C10.9142 12.5 11.25 12.8358 11.25 13.25C11.25 13.6297 10.9678 13.9435 10.6018 13.9932L10.5 14H1.5C1.08579 14 0.75 13.6642 0.75 13.25C0.75 12.8703 1.03215 12.5565 1.39823 12.5068L1.5 12.5H10.5H1.5ZM5.89823 0.506847L6 0.5C6.3797 0.5 6.69349 0.782154 6.74315 1.14823L6.75 1.25V8.938L9.0052 6.68414C9.27147 6.41787 9.68813 6.39366 9.98175 6.61152L10.0659 6.68414C10.3321 6.9504 10.3563 7.36707 10.1385 7.66068L10.0659 7.7448L6.53033 11.2803C6.26406 11.5466 5.8474 11.5708 5.55379 11.3529L5.46967 11.2803L1.93414 7.7448C1.64124 7.4519 1.64124 6.97703 1.93414 6.68414C2.2004 6.41787 2.61707 6.39366 2.91068 6.61152L2.9948 6.68414L5.25 8.94V1.25C5.25 0.870304 5.53215 0.556509 5.89823 0.506847L6 0.5L5.89823 0.506847Z" fill={color} />
        </svg>
    );
};
