import { Footer } from "components/footer";
import { Header } from "components/header";
import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { Etude } from "views/etude";
import { Observatoire } from "views/observatoire";
import { Presse } from "views/presse";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="*" element={<Navigate to='/etude' />} />
        <Route path="/etude" element={<Etude />} />
        <Route path="/observatoire" element={<Observatoire />} />
        <Route path="/presse" element={<Presse />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
