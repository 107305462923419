import { IPresse } from "models/IPresse";
import http from "../http";

class PresseService {

    getAllPresse(lang: string, page: number) {
        return http.get(`/api/presses?_sort=date:ASC&locale=${lang}&populate=*&pagination[page]=${page}&pagination[pageSize]=4`)
            .then(response => {
                const data = response.data;
                return data;
            }).catch(error => {
                console.log(error);
            });
    }

    sortByDateDescending(presses:IPresse[]) {
        const months = [
          "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
          "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
      
       return presses.sort((a, b) => {
        const dateSplitA = a.date ? a.date.split(" ") : [];
        const dateSplitB = b.date ? b.date.split(" ") : [];
    
        if (dateSplitA?.length === 2 && dateSplitB?.length === 2) {
          const [monthA, yearA] = dateSplitA;
          const [monthB, yearB] = dateSplitB;
    
          const monthIndexA = months.indexOf(monthA);
          const monthIndexB = months.indexOf(monthB);
    
          if (yearA !== yearB) {
            return parseInt(yearB) - parseInt(yearA);
          } else {
            return monthIndexB - monthIndexA;
          }
        } else {
          return 0;
        }
      });
      
      }
}



export default new PresseService();