import { EtudeHeader } from "components/etudeHeader";
import { EtudeKpi } from "components/etudeKpi";
import { Expertise } from "components/expertise";
import { IEtude } from "models/IEtudes";
import React, { FunctionComponent } from "react";
import "./etude.scss";

interface EtudeProps {
    etude: IEtude;
}

export const EtudePage: FunctionComponent<EtudeProps> = ({ etude }) => {


    return (
        <div className="etude">
            <p className="etude__title open-sans-700">N°{etude.collection} - {etude.month} {etude.year}</p>
            <EtudeHeader title={etude.title1} subtitle={etude.subtitle1} />
            <div className="etude__kpi">
                <EtudeKpi value={etude.kpi1Title} text={etude.kpi1Text} type={"primary"} even={false} />
                <EtudeKpi value={etude.kpi2Title} text={etude.kpi2Text} type={"secondary"} even={true} />
            </div>
            <div className="etude__separator"></div>
            <EtudeHeader title={etude.title2} subtitle={etude.subtitle2} />
            <div className="etude__expertise">
                <Expertise expertise={{ name: etude.name, job: etude.job, observation: etude.observation, link: etude.link, etude: etude.etude, image: etude.image, bfmLink: etude.bfmLink }} />
            </div>
        </div>
    );
};
