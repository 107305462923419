import React, { FunctionComponent } from "react";
import "./etudeHeader.scss";

interface EtudeHeaderProps {
    title?: string;
    subtitle?: string;
}

export const EtudeHeader: FunctionComponent<EtudeHeaderProps> = ({ title, subtitle }) => {


    return (
        <div className="etudeHeader">
            <p className="etudeHeader__title gilroy-700">{title}</p>
            <p className="etudeHeader__text">{subtitle}</p>
        </div>
    );
};
