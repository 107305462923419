// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  display: flex;
  flex-direction: row;
  padding: 1.071rem 1.143rem;
  border-radius: 8px;
  gap: 1.143rem;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  margin: auto;
}
.button-link {
  margin: 0;
  font-size: 1.071rem;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}
.button img {
  width: 1.071rem;
  height: 1.071rem;
  align-self: center;
}

.color--primary {
  background-color: #31314b;
}

.button:hover .onHover {
  color: #ff881a;
}`, "",{"version":3,"sources":["webpack://./src/components/button/button.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,mBAAA;EACA,YAAA;AADJ;AAEI;EACI,SAAA;EACA,mBAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cClBM;ADkBd;AAEI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AAAR;;AAGA;EACI,yBCzBsB;ADyB1B;;AAEA;EACI,cC1BwB;AD2B5B","sourcesContent":["@import \"assets/styles/colors.scss\";\n\n.button {\n    display: flex;\n    flex-direction: row;\n    padding: 1.071rem 1.143rem;\n    border-radius: 8px;\n    gap: 1.143rem;\n    text-decoration: none;\n    width: fit-content;\n    align-items: center;\n    margin: auto;\n    &-link {\n        margin: 0;\n        font-size: 1.071rem;\n        letter-spacing: 0.25em;\n        text-transform: uppercase;\n        text-align: center;\n        color: $color_white;\n    }\n    img {\n        width: 1.071rem;\n        height: 1.071rem;\n        align-self: center;\n    }\n}\n.color--primary {\n    background-color: $color_observatoire-navy;\n}\n.button:hover .onHover {\n    color: $color_observatoire-orange;\n}\n","$color_white: #ffffff;\n$color_observatoire-night: #151520;\n$color_observatoire-navy: #31314b;\n$color_observatoire-navy2: #4100FB;\n$color_observatoire-orange: #ff881a;\n$color_dusty-orange: #ebc8a7;\n$color_dusty-pink: #eaded9;\n$color_dark-grey: #6f6d69;\n$color_anthracite: #34322d;\n$color_light-grey: #e1e1e1;\n$color_light-grey2:#CCC9D4;\n$color_havanna: #f4ece4;\n$color_sweet-cream: #F0E9E6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
