import React, { FunctionComponent } from "react";

interface Props {
    color?: string;
}

export const Arrow2: FunctionComponent<Props> = ({
    color,
}) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.74609 0.638672C2.55469 0.638672 2.39062 0.707031 2.25391 0.84375C2.11719 0.971354 2.04883 1.13086 2.04883 1.32227C2.04883 1.51367 2.11719 1.67773 2.25391 1.81445C2.39062 1.95117 2.55469 2.01953 2.74609 2.01953H8.88477L0.955078 9.93555C0.891276 9.99935 0.836589 10.0768 0.791016 10.168C0.754557 10.25 0.736328 10.3411 0.736328 10.4414C0.736328 10.6328 0.804688 10.7969 0.941406 10.9336C1.07812 11.0703 1.24219 11.1387 1.43359 11.1387C1.53385 11.1387 1.625 11.1204 1.70703 11.084C1.79818 11.0384 1.87565 10.9837 1.93945 10.9199L9.85547 2.99023V9.12891C9.85547 9.32031 9.92383 9.48438 10.0605 9.62109C10.1973 9.75781 10.3613 9.82617 10.5527 9.82617C10.7441 9.82617 10.9036 9.75781 11.0312 9.62109C11.168 9.48438 11.2363 9.32031 11.2363 9.12891V1.32227C11.2363 1.27669 11.2318 1.23112 11.2227 1.18555C11.2135 1.13997 11.1999 1.0944 11.1816 1.04883V1.0625C11.1361 0.94401 11.0586 0.848307 10.9492 0.775391C10.849 0.693359 10.7305 0.647786 10.5938 0.638672H2.74609Z" fill={color} />
        </svg>

    );
};
