import React, { FunctionComponent } from "react";
import "./etudeKpi.scss";

interface EtudeKpiProps {
    value?: number;
    text?: string;
    even: boolean;
    type: string;
}

export const EtudeKpi: FunctionComponent<EtudeKpiProps> = ({ value, text, even, type }) => {


    return (
        <div className={"etudeKpi " + (even ? "etudeKpi-right" : "etudeKpi-left")}>
            <p className={`etudeKpi__value gilroy-600 etudeKpi__color-${type}`}>{value}%</p>
            <p className={`etudeKpi__text etudeKpi__color-${type}`}>{text}</p>
        </div>
    );
};
