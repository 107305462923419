// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container2 {
  position: relative;
}
.container2 .etuTitle {
  padding-bottom: 2.857rem;
}
@media screen and (max-width: 767px) {
  .container2 .etuTitle {
    display: none;
  }
}
.container2 .burger {
  display: none;
}
@media screen and (max-width: 767px) {
  .container2 .burger {
    display: block;
    margin-bottom: 2.286rem;
  }
}
@media screen and (max-width: 767px) {
  .container2 .open {
    display: block;
    margin-bottom: 2.286rem;
    border-bottom: 1px solid #151520;
    transition: 1s ease;
    height: 100%;
  }
  .container2 .close {
    display: none;
    transition: 1s ease;
    height: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/etude/style.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;AAEI;EACI,wBAAA;AAAR;AACQ;EAFJ;IAGQ,aAAA;EAEV;AACF;AAAI;EACI,aAAA;AAER;AADQ;EAFJ;IAGQ,cAAA;IACA,uBAAA;EAIV;AACF;AAFI;EACI;IACI,cAAA;IACA,uBAAA;IACA,gCAAA;IACA,mBAAA;IACA,YAAA;EAIV;EAFM;IACI,aAAA;IACA,mBAAA;IACA,SAAA;EAIV;AACF","sourcesContent":["@import 'assets/styles/colors.scss';\n\n.container2{\n    position: relative;\n    .etuTitle{\n        padding-bottom: 2.857rem;\n        @media screen and (max-width: 767px) {\n            display: none;\n        }\n    }\n    .burger {\n        display: none;\n        @media screen and (max-width: 767px) {\n            display: block;\n            margin-bottom: 2.286rem;\n        }\n    }\n    @media screen and (max-width: 767px) {\n        .open{\n            display: block;\n            margin-bottom: 2.286rem;\n            border-bottom: 1px solid $color_observatoire-night;\n            transition: 1s ease;\n            height: 100%;\n        }\n        .close{\n            display: none;\n            transition: 1s ease;\n            height: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
