import { Burger } from "assets/icons/burger";
import { AnimateArrow } from "components/arrowAnimate/arrow";
import { Banner } from "components/banner";
import { EtudePage } from "components/etude";
import { MiniNavbar } from "components/miniNavbar";
import { PlanetOrbit } from "components/planetOrbit";
import { IBanner } from "models/IBanner";
import { IEtude } from "models/IEtudes";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { handleScroll } from "shared/utils/planetScroll";
import { allEtudes, findOneEtude, lastEtude } from "./service";

import "./style.scss";
import { useNavigate } from 'react-router-dom';

export const Etude: FunctionComponent = () => {
  const collection = Number(window.location.search.split("=")[1]);

  const [etude, setEtude] = useState<IEtude>({} as IEtude);
  const [listY, setYears] = useState<IBanner[]>([]);
  const [openBurger, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const windowSize = window.screen.width;
  const navigate = useNavigate();

  useEffect(() => {
    console.log("etude");
    async function initPage() {
      const etude = await lastEtude();
      const etudes = await allEtudes();
      let oneEtude: IEtude;
      if (window.location.search === "") {
        navigate(`?edition=${etude.collection}`);
        setEtude(etude);
      } else {
        let obj = etudes?.find((o) => o.collection === collection);
        if (!obj) {
          setEtude(etude);
          navigate(`?edition=${etude.collection}`);
        } else {
          oneEtude = await findOneEtude(collection);
          setEtude(oneEtude);
        }
      }
      let listYear: string[] = [];
      etudes?.length !== 0 && etudes?.forEach((item: IEtude) => {
        if (!listYear.includes(item.year)) listYear.push(item.year)
      });
      let list: IBanner[] = [];
      listYear?.forEach((index) => {
        list.push({ year: index, titles: etudes.filter((item: IEtude) => item.year === index).map((item) => ({ title: item.title1!, collection: item.collection! })).sort((a, b) => { return b.collection - a.collection }) })
        setYears(list.sort((a, b) => Number(a.year) < Number(b.year) ? 1 : -1))
      })
    }
    initPage();
    if (windowSize < 1250) window.scrollTo(0, 0);
    //planet scrrol
    anchorRef.current?.scrollIntoView({ behavior: "smooth" });
    window.addEventListener("scroll", () => handleScroll());
    if (windowSize > 1250) window.scrollTo(0, 0);
    return () => window.removeEventListener("scroll", () => handleScroll());
    }, [collection, windowSize]);

  const handleBurger = () => {
    openBurger ? setOpen(false) : setOpen(true);
  };

  return (
    <div   className="container2">
      <div ref={anchorRef} onClick={() => handleBurger()} className="burger">
        <Burger background={openBurger ? "#31314B" : "none"} />
      </div>
      <div className={(openBurger ? "open" : "close")}>
        <Banner banner={listY} />
      </div>
      {/* <div className="etuTitle">
        <PageHeader text={"L’étude"} points={false} />
      </div> */}
      <PlanetOrbit />
      <AnimateArrow />
      {etude && <EtudePage etude={etude} />}
      <MiniNavbar left={false} right={true} text1={"L’OBSERVATOIRE"} link1={"/observatoire"} text2={"presse"} link2={"/presse?page=1"} />
    </div>
  );
};
