// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  flex-direction: row;
  grid-column: 3/12;
  grid-row: 10/13;
  gap: 64px;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .navbar {
    grid-column: 2/8;
    grid-row: 16/17;
    gap: 1.714rem;
    justify-content: center;
  }
}
.navbar-link {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 1rem;
  line-height: 19px;
  letter-spacing: 0.25em;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;
  color: #e1e1e1;
}
@media screen and (max-width: 767px) {
  .navbar-link {
    font-family: "Open Sans";
    font-weight: 600;
  }
}
.navbar-link:hover {
  color: #ff881a;
}
.navbar .active {
  color: #ff881a;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,SAAA;EACA,mBAAA;AADJ;AAEI;EAPJ;IAQQ,gBAAA;IACA,eAAA;IACA,aAAA;IACA,uBAAA;EACN;AACF;AAAI;EACI,wBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,iBAAA;EACA,yBAAA;EACA,qBAAA;EACA,cCfW;ADiBnB;AADQ;EAVJ;IAWQ,wBAAA;IACA,gBAAA;EAIV;AACF;AAFI;EACI,cC3BoB;AD+B5B;AAFI;EACI,cC9BoB;ADkC5B","sourcesContent":["@import 'assets/styles/colors.scss';\n\n.navbar {\n    display: flex;\n    flex-direction: row;\n    grid-column: 3/12;\n    grid-row: 10/13;\n    gap: 64px;\n    align-items: center;\n    @media screen and (max-width: 767px) {\n        grid-column: 2/8;\n        grid-row: 16/17;\n        gap: 1.714rem;\n        justify-content: center;\n    }\n    &-link {\n        font-family: \"Open Sans\";\n        font-weight: 700;\n        font-size: 1rem;\n        line-height: 19px;\n        letter-spacing: 0.25em;\n        text-align: right;\n        text-transform: uppercase;\n        text-decoration: none;\n        color: $color_light-grey;\n        @media screen and (max-width: 767px) {\n            font-family: \"Open Sans\";\n            font-weight: 600;\n        }\n    }\n    &-link:hover{\n        color: $color_observatoire-orange; \n    }\n    .active{\n        color: $color_observatoire-orange;\n    }\n}\n","$color_white: #ffffff;\n$color_observatoire-night: #151520;\n$color_observatoire-navy: #31314b;\n$color_observatoire-navy2: #4100FB;\n$color_observatoire-orange: #ff881a;\n$color_dusty-orange: #ebc8a7;\n$color_dusty-pink: #eaded9;\n$color_dark-grey: #6f6d69;\n$color_anthracite: #34322d;\n$color_light-grey: #e1e1e1;\n$color_light-grey2:#CCC9D4;\n$color_havanna: #f4ece4;\n$color_sweet-cream: #F0E9E6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
