import React, { FunctionComponent } from "react";

interface Props {
    color?: string;
}

export const Line: FunctionComponent<Props> = ({
    color,
}) => {
    return (
        <svg width="30" height="10" viewBox="0 0 30 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.4419 5.44194C29.686 5.19786 29.686 4.80214 29.4419 4.55806L25.4645 0.580583C25.2204 0.336505 24.8247 0.336505 24.5806 0.580583C24.3365 0.82466 24.3365 1.22039 24.5806 1.46447L28.1161 5L24.5806 8.53553C24.3365 8.77961 24.3365 9.17534 24.5806 9.41942C24.8247 9.6635 25.2204 9.6635 25.4645 9.41942L29.4419 5.44194ZM0 5.625H29V4.375H0L0 5.625Z" fill={color} />
        </svg>
    );
};
