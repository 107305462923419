import { AnimateArrow } from "components/arrowAnimate/arrow";
import { MiniNavbar } from "components/miniNavbar";
import { PageHeader } from "components/pageHeader";
import { PlanetOrbit } from "components/planetOrbit";
import { PresseItem } from "components/presse";
import { IPresse } from "models/IPresse";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { handleScroll } from "shared/utils/planetScroll";
import { allPresse } from "./service";
import "./style.scss";

interface clickPage {
  selected: number;
}

export const Presse: FunctionComponent = () => {
  const [presses, setPresses] = useState([] as IPresse[]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(
    Number(window.location.search.split("=")[1]) - 1
  );
  const [postsPerPage] = useState(4);
  const [offset, setOffset] = useState(page * postsPerPage);
  const anchorRef = useRef<HTMLDivElement>(null);
  const windowSize = window.screen.width;
  const navigate = useNavigate();

  useEffect(() => {
    console.log("presse: infinite loop");
    const getAllPresses = async () => {
      if (window.location.search === "") {
        navigate(`?page=1`);
      }
      const presse = await allPresse("fr", (page || 0) + 1);
      if (presse?.length === 0) {
        navigate(`?page=1`);
      }
      setPresses(presse);
      if (presse?.length > 0) {
        console.log(presse);
        setPageCount(Math.ceil(presse[0].total / postsPerPage));
      }
    };
    getAllPresses();
    if (windowSize < 1250) window.scrollTo(0, 0);
    anchorRef.current?.scrollIntoView({ behavior: "smooth" });
    window.addEventListener("scroll", () => handleScroll());
    if (windowSize > 1250) window.scrollTo(0, 0);
    return () => window.removeEventListener("scroll", () => handleScroll());
  }, [offset, postsPerPage, windowSize]);

  const handlePageClick = (event: clickPage) => {
    const selectedPage = event.selected;
    setPage(selectedPage);
    setOffset(selectedPage * postsPerPage);
    navigate(`?page=${event.selected + 1}`);
    window.scrollTo(0, 0);
  };

  return (
    <div ref={anchorRef} className="container">
      <PlanetOrbit />
      <div className="presseTitle">
        <PageHeader text={"Revue de presse"} points={false} />
      </div>
      <div className="presseList">
        {presses?.length !== 0 &&
          presses?.map((item, index) => {
            return (
              <div key={index}>
                <PresseItem presse={item} />
              </div>
            );
          })}
      </div>
      <AnimateArrow />

      {pageCount > 1 && (
        <ReactPaginate
          previousLabel={""}
          nextLabel={""}
          breakLabel={"..."}
          pageCount={pageCount || 10}
          onPageChange={handlePageClick}
          forcePage={page || 10}
          containerClassName={"pagination"}
          activeClassName={"active"}
        /> 
      )}
      <MiniNavbar
        left={true}
        right={false}
        text1={"études"}
        link1={"/etude"}
        text2={"L’OBSERVATOIRE"}
        link2={"/observatoire"}
      />
    </div>
  );
};
