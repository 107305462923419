import { AnimateArrow } from "components/arrowAnimate/arrow";
import { Bullet } from "components/bullet";
import { Button } from "components/button";
import { MiniNavbar } from "components/miniNavbar";
import { BlocObservatoire } from "components/observatoire";
import { PageHeader } from "components/pageHeader";
import { PlanetOrbit } from "components/planetOrbit";
import React, { FunctionComponent, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { handleScroll } from "shared/utils/planetScroll";
import "./style.scss";

export const Observatoire: FunctionComponent = () => {

    const { pathname } = useLocation();
    const anchorRef = useRef<HTMLDivElement>(null);
    const windowSize = window.screen.width;

    useEffect(() => {
        if(windowSize < 1250) window.scrollTo(0, 0);
        anchorRef.current?.scrollIntoView({ behavior: 'smooth' });
        window.addEventListener('scroll', () => handleScroll());
        if(windowSize > 1250) window.scrollTo(0, 0);
        return () => window.removeEventListener('scroll', () => handleScroll());
    }, [pathname, windowSize]);

    return (
        <div ref={anchorRef} className="container">
            <AnimateArrow />
            <div className="obsTitle">
                <PageHeader text={"Qu’est-ce que"} points={true} />
            </div>
            <PlanetOrbit />
            <BlocObservatoire />
            <div className="bulletList">
                <Bullet title={"L’Observatoire, qu’est-ce que c’est ?"}>
                    <div className="fontText">
                        <p>
                            Un exercice d’analyse tant basé sur nos activités que sur les tendances du marché. Vous pouvez y retrouver <span className="textColor">une synthèse des nouveaux usages</span> (consommation, travail, développement durable…) qui semblent émerger en France.
                        </p>
                    </div>
                </Bullet>
                <Bullet title={"La méthode"} >
                    <div className="fontText">
                        <p>
                            Nous interrogeons tous les deux mois, aux côtés de notre partenaire l’institut de sondage Odoxa, <span className="textColor">Français et professionnels de la technologie, du numérique et de l’innovation.</span>
                        </p>
                        <p>
                            Fidèle à nos convictions, nous privilégions les retours d’expériences et illustrations concrètes, tentons d’adresser des sujets touchant tous les Français, salariés, équipes de terrain et dirigeants d’entreprises.
                        </p>
                        <p>
                            Vous pouvez retrouver ici la synthèse de nos études, l’avis de nos expert·e·s et nos interventions sur le plateau de Frédéric Simottel, Rédacteur en chef de Tech & Co Business.
                        </p>
                    </div>
                </Bullet>
                <Bullet title={"Au programme"}>
                    <div className="fontText">
                        <p> <span className="textColor">Les thématiques qui façonnent le quotidien des Français·es et salarié·e·s,</span> comme :</p>
                        <ul>
                            <li>La crise énergétique : conséquences, opportunités et nouveaux comportements</li>
                            <li>L’évolution du marché de l'emploi et des entreprises</li>
                            <li>La cybersécurité</li>
                            <li>L’intelligence artificielle va-t-elle changer mon métier ?</li>
                            <li>Les secteurs proposant des nouvelles méthodes de travail</li>
                        </ul>
                        <p>
                            Nos expériences, retours de mission et partages avec nos clients sont garants d’une analyse pragmatique participant à partager des changements d’usages, toujours « pour de vrai ».
                        </p>
                    </div>
                </Bullet>
                <Bullet title={"Nous vous donnons la parole"}>
                    <div className="fontText">
                        <p>
                            Quel(s) sujet(s) souhaiteriez-vous que nous abordions dans la prochaine édition de L’Observatoire ? Faites-nous part de vos suggestions :
                    </p>
                    </div>
                </Bullet>
            </div>
            <Button button={{ text: "J’ai une suggestion", color: "primary", type: "type1" }} />
            <MiniNavbar left={true} right={true} text1={"études"} link1={"/etude"} text2={"presse"} link2={"/presse?page=1"} />
        </div>
    );
};